// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bodega-js": () => import("/opt/build/repo/src/pages/bodega.js" /* webpackChunkName: "component---src-pages-bodega-js" */),
  "component---src-pages-contacto-js": () => import("/opt/build/repo/src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-vinos-js": () => import("/opt/build/repo/src/pages/vinos.js" /* webpackChunkName: "component---src-pages-vinos-js" */),
  "component---src-pages-vinos-corazon-loco-blanco-js": () => import("/opt/build/repo/src/pages/vinos/corazon-loco-blanco.js" /* webpackChunkName: "component---src-pages-vinos-corazon-loco-blanco-js" */),
  "component---src-pages-vinos-corazon-loco-tinto-js": () => import("/opt/build/repo/src/pages/vinos/corazon-loco-tinto.js" /* webpackChunkName: "component---src-pages-vinos-corazon-loco-tinto-js" */),
  "component---src-pages-vinos-corazon-spumante-rosado-js": () => import("/opt/build/repo/src/pages/vinos/corazon-spumante-rosado.js" /* webpackChunkName: "component---src-pages-vinos-corazon-spumante-rosado-js" */),
  "component---src-pages-vinos-dulce-corazon-js": () => import("/opt/build/repo/src/pages/vinos/dulce-corazon.js" /* webpackChunkName: "component---src-pages-vinos-dulce-corazon-js" */),
  "component---src-pages-vinos-minuto-116-tinto-js": () => import("/opt/build/repo/src/pages/vinos/minuto-116-tinto.js" /* webpackChunkName: "component---src-pages-vinos-minuto-116-tinto-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

